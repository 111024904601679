import {
  useField,
  useFormFieldGroup,
  useFormSection
} from "@/components/FormBuilder/Helpers";
import store from "@/store";

export const readOnlyFields = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useField({
            key: "agencyName",
            label: "Agency Name",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "status",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "status",
            label: "Agency Status",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "status",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "agencyCode",
            label: "Agency Code",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "agencyCode",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "parentAgency.agencyName",
            label: "Sub Agency Of",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "subAgencyOf",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "parentAgencyCode",
            label: "Parent Agency Code",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "parentAgencyCode",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "3-col" }
      ),

      ...useFormFieldGroup(
        [
          useField({
            key: "agencyCompanies",
            label: "Agency Companies",
            type: "dataview"
          })
        ],
        { layout: "full" }
      )
    ],
    {
      title: "Agency Information",
      description: "Provide some basic information about the agency"
    }
  ),
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useField({
            key: "underWriterData.fullName",
            label: "Underwriter Name",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "underWriterData.fullName",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "full" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "underWriterData.email",
            type: "dataview",
            label: "Email",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "underWriterData.email",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "underWriterData.phone.primary",
            label: "Primary Phone number",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "underWriterData.phone.primary",
                  value: true
                }
              ]
            }
          }),
          useField({
            key: "underWriterData.phone.secondary",
            label: "Secondary Phone number",
            type: "dataview",
            conditions: {
              and: [
                {
                  operator: "exists",
                  field: "underWriterData.phone.secondary",
                  value: true
                }
              ]
            }
          })
        ],
        { layout: "2-col" }
      )
    ],

    {
      title: "Underwriter Information",
      description: "Information about the underwriter assigned the agency"
    }
  )
];

export function readOnlyAgencyFormFields() {
  if (
    store.getters["agency/getEditingAgency"] &&
    !store.getters["agency/getEditingAgency"].underWriterData
  ) {
    readOnlyFields.pop();
  }
  return readOnlyFields;
}
