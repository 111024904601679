import { defaultAddressStructure } from "./defaultObjects";

export function sameAsPhysicalAddressChangedHandler(
  key: any,
  fieldValue: boolean,
  editing: Record<string, any>,
  mutation: Function
) {
  if (fieldValue) {
    mutation({
      key,
      value: { ...editing }
    });
  } else {
    mutation({
      key,
      value: { ...defaultAddressStructure }
    });

    const id = "field_field_houseNumber";
    const nodes = document.querySelectorAll(`#${id}`);
    if (nodes && nodes.length >= 2) {
      const target = nodes[1];
      target.querySelector("input")?.focus();
    }
  }
}
